<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="`${value.firstname} ${value.lastname}`"
          class="w-full mt-4"
          label="Name"
          disabled/>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="value.email"
          class="w-full mt-4"
          label="Email"
          disabled/>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="value.phone_number"
          class="w-full mt-4"
          label="Phone Number"
          disabled/>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <div class="vs-component vs-con-input-label vs-input w-full mt-4 vs-input-primary">
        <label class="vs-input--label">Message</label>
        <textarea
          :value="value.message"
          class="w-full vs-inputx vs-input--input normal hasValue"
          style="border: 1px solid rgba(0, 0, 0, 0.15);background:rgba(0, 0, 0, 0.15);opacity:.5"
          readonly/>
        </div>
      </div>
    </div>

<!--    <div class="vx-row" v-if="value.id">
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="dateDisplay(value.created_at)"
          class="w-full"
          label="Created At"
          disabled/>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <vs-input
          :value="dateDisplay(value.updated_at)"
          class="w-full"
          label="Updated At"
          disabled/>
      </div>
    </div>-->

    <!-- Content Row -->
    <div class="vx-row mt-4">
      <div class="vx-col w-full">
        <vs-textarea
          v-model="value.remark"
          class="w-full mt-4"
          label="Remark"
          name="remark"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('name')">
          {{ errors.first('remark') }}
        </span>
      </div>
    </div>

  </div>

</template>

<script>
import { default as infoMixin } from '@/mixins/infoMixin'

export default {
  name: 'ContactUsGeneral',
  mixins: [infoMixin],
  props: {
    value: {
      type: Object,
    },
  },
}
</script>
