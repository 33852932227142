
import constants from '@/utils/constants'

export default {
  data:() => ({
    locked: false,
    loaded: false,
    schema: {},
    info: {},
    statusOptions: constants.statusOptions,
  }),

  methods: {
    showLoading () {
      this.$vs.loading()
      this.lodash.set(this, 'loaded', false)
    },
    hideLoading () {
      this.$vs.loading.close()
      this.lodash.set(this, 'loaded', true)
    },
    validate () {
      return this.$validator.validateAll()
    },
    resetData () {
      Object.assign(
        this.info,
        this.lodash.pick(this.$info, Object.keys(this.schema)),
      )
    },
    dateDisplay (date) {
      if (this.lodash.isEmpty(date)) {
        return ''
      }
      return this.$helpers.dateFormat(date, 'LLL')
    },
  },

  computed: {
    hasErrors () {
      return !this.errors.any()
    },
  },
  beforeMount () {
    this.locked = true
  },

  mounted () {
    this.locked = false
  },
  beforeDestroy () {
    if (!this.lodash.isUndefined(this.reset)) {
      this.reset()
    }
  },

  watch: {
    locked (value) {
      // console.log({ watch: 'locked', value })
      if (value === true) {
        this.$vs.loading()
      } else {
        this.$vs.loading.close()
      }
    },
    $info (to) {
      Object.assign(
        this.info,
        this.lodash.pick(to, Object.keys(this.schema)),
      )
    },
  },
}
